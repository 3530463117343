import React from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const Home = () => {
  return (
    <div className="body-inner">
      <Header />
      <div className="banner-carousel banner-carousel-2 mb-0">
        <div
          className="banner-carousel-item"
          style={{ backgroundImage: "url(./images/new/slide1.jpg)" }}></div>
        <div
          className="banner-carousel-item"
          style={{
            backgroundImage: "url(./images/new/3.jpeg)",
          }}></div>
      </div>

      <section className="call-to-action no-padding">
        <div className="container">
          <div className="action-style-box">
            <div className="row">
              <div className="col-md-8 text-center text-md-left">
                <div className="call-to-action-text">
                  <h3 className="action-title">We understand your needs</h3>
                </div>
              </div>
              {/* Col end */}
              <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                <div className="call-to-action-btn">
                  <a className="btn btn-primary" href="/about">
                    Contact Us Now
                  </a>
                </div>
              </div>
              {/* col end */}
            </div>
            {/* row end */}
          </div>
          {/* Action style box */}
        </div>
        {/* Container end */}
      </section>
      {/* Action end */}

      <section id="ts-features" className="ts-features pb-2 bg-light">
        <div className="container">
          <h4 className="text-center">Hotel Locks</h4>
          <div className="row">
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper">
                  <img
                    loading="lazy"
                    className="w-100"
                    src="images/products/locks/hotel (6).png"
                    alt="service"
                  />
                </div>
              </div>
              {/* Service1 end */}
            </div>
            {/* Col 1 end */}
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper">
                  <img
                    loading="lazy"
                    className="w-100"
                    src="./images/products/locks/apartment (2).png"
                    alt="service"
                  />
                </div>
              </div>
              {/* Service3 end */}
            </div>
            {/* Col 3 end */}
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper">
                  <img
                    loading="lazy"
                    className="w-100"
                    src="/images/products/locks/fingerprint (4).png"
                    alt="service"
                  />
                  {/* <img
                    loading="lazy"
                    className="w-100"
                    src="./images/products/locks/apartment.png"
                    alt="service"
                  /> */}
                </div>
              </div>
              {/* Service2 end */}
            </div>
            {/* Col 2 end */}
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper">
                  <img
                    loading="lazy"
                    className="w-100"
                    src="./images/products/locks/hotel (2).png"
                    alt="service"
                  />
                </div>
              </div>
              {/* Service3 end */}
            </div>
            {/* Col 3 end */}
          </div>
          {/* Content row end */}
        </div>
        {/* Container end */}
      </section>
      {/* Feature are end */}

      <section id="ts-features" className="ts-features pb-2 bg-light">
        <div className="container">
          <h4 className="text-center">Other items Available</h4>
          <div className="row">
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper">
                  <img
                    loading="lazy"
                    className="w-100"
                    src="./images/products/locks/rfid.png"
                    alt="service"
                  />
                  {/* <img
                    loading="lazy"
                    className="w-100"
                    src="./images/products/locks/fingerprint (4).png"
                    alt="service"
                  /> */}
                </div>
              </div>
              {/* Service1 end */}
            </div>
            {/* Col 1 end */}
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper">
                  <img
                    loading="lazy"
                    className="w-100"
                    src="./images/new/hotel-card-switch.png"
                    alt="service"
                  />
                </div>
              </div>
              {/* Service3 end */}
            </div>
            {/* Col 3 end */}
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper text-center">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src="./images/new/finger.png"
                    alt="service"
                  />
                </div>
              </div>
              {/* Service2 end */}
            </div>
            {/* Col 2 end */}
            <div className="col-lg-3 col-6 mb-5">
              <div className="ts-service-box">
                <div className="ts-service-image-wrapper text-center">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src="./images/new/encoder.png"
                    alt="service"
                  />
                </div>
              </div>
              {/* Service3 end */}
            </div>
            {/* Col 3 end */}
          </div>
          {/* Content row end */}
        </div>
        {/* Container end */}
      </section>

      <section id="ts-service-area" className="ts-service-area pb-0 bg-light">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h2 className="section-title">We Are Specialists In</h2>
              <h3 className="section-sub-title">What We Do</h3>
            </div>
          </div>
          {/*/ Title row end */}
          <div className="row">
            <div className="col-lg-6">
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="https://img.icons8.com/dotty/60/000000/web.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="#/">Customize Software Development</a>
                  </h3>
                  <p>We can tweak your software to suit your needs.</p>
                </div>
              </div>
              {/* Service 1 end */}
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="https://img.icons8.com/cute-clipart/64/000000/web.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="#/">Website Development</a>
                  </h3>
                  <p>
                    We are able to turn your ideas into reality with our
                    excellent website engineers
                  </p>
                </div>
              </div>
              {/* Service 2 end */}
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="https://img.icons8.com/ios/50/000000/management.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="#/">Facility Management</a>
                  </h3>
                  <p>
                    We do maintenance and repair of all types of facilities and
                    also offer reception Services
                  </p>
                </div>
              </div>
              {/* Service 3 end */}
            </div>
            {/* Col end */}
            <div className="col-lg-6 mt-5 mt-lg-0 mb-4 mb-lg-0">
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-access-control-security-flaticons-flat-flat-icons.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="#/">Networking / CCTV Installation</a>
                  </h3>
                  <p>
                    We work on all sorts of Networking Installation and CCTV
                    camera Installation.
                  </p>
                </div>
              </div>
              {/* Service 2 end */}
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="https://img.icons8.com/external-flaticons-flat-flat-icons/65/000000/external-access-control-privacy-flaticons-flat-flat-icons.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="#/">
                      RFID Key cards / Access Control Installation
                    </a>
                  </h3>
                  <p>
                    We work on all sorts of RFID Key cards Locking Systems
                    Installation and Keypad Locks.
                  </p>
                </div>
              </div>
              {/* Service 3 end */}
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="https://img.icons8.com/ultraviolet/45/000000/alarm.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="#/">Alarm Systems</a>
                  </h3>
                  <p>We work on all sorts of Alarm systems Installation</p>
                </div>
              </div>
              {/* Service 6 end */}
            </div>
            {/* Col end */}
          </div>
          {/* Content row end */}
        </div>
        {/*/ Container end */}
      </section>
      {/* Service end */}

      <Footer />
    </div>
  );
};

export default Home;
