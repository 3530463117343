import React from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const Locks = () => {
  return (
    <>
      <Header />
      <div className="body-inner">
        <div
          id="banner-area"
          className="banner-area"
          style={{ backgroundImage: "url(./images/new/header-1.jpg)" }}>
          <div className="banner-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-heading">
                    <h1 className="banner-title">Locks</h1>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                          <a href="#">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">locks</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          All locks
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                {/* Col end */}
              </div>
              {/* Row end */}
            </div>
            {/* Container end */}
          </div>
          {/* Banner text end */}
        </div>
        {/* Banner area end */}
        <section id="main-container" className="main-container pb-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/hotel (6).png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service1 end */}
              </div>
              {/* Col 1 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/apartment (2).png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service3 end */}
              </div>
              {/* Col 6 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/apartment (3).png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service2 end */}
              </div>
              {/* Col 2 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/apartment.png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service3 end */}
              </div>
              {/* Col 3 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/fingerprint (1).png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service1 end */}
              </div>
              {/* Col 4 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/fingerprint (2).png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service2 end */}
              </div>

              {/* Col 1 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/hotel (1).png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service3 end */}
              </div>
              {/* Col 6 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/hotel (2).png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service2 end */}
              </div>
              {/* Col 2 end */}

              {/* Col 1 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/apartment (1).png "
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service3 end */}
              </div>

              {/* Col 2 end */}
              <div className="col-lg-3 col-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      src="images/products/locks/apartment.png"
                      alt="service"
                    />
                  </div>
                </div>
                {/* Service3 end */}
              </div>
              {/* Col 3 end */}
            </div>
            {/* Main row end */}
          </div>
          {/* Conatiner end */}
        </section>
        {/* Main container end */}
      </div>

      <Footer />
    </>
  );
};

export default Locks;
