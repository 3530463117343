import React from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const Pms = () => {
  return (
    <div>
      <Header />
      <div>
        <div
          id="banner-area"
          className="banner-area"
          style={{ backgroundImage: "url(./images/new/header-1.jpg)" }}>
          <div className="banner-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-heading">
                    <h1 className="banner-title">Services</h1>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#/">Services</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          PMS
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                {/* Col end */}
              </div>
              {/* Row end */}
            </div>
            {/* Container end */}
          </div>
          {/* Banner text end */}
        </div>
        {/* Banner area end */}
        <section id="main-container" className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4">
                <div className="sidebar sidebar-left">
                  <div className="widget">
                    <h3 className="widget-title">Features</h3>
                    <ul className="nav service-menu">
                      <li className="active">
                        <a href="#/">Frondesk(Checkin/Checkout)</a>
                      </li>
                      <li>
                        <a href="#/">House Keeping</a>
                      </li>
                      <li>
                        <a href="#/">Maintenance</a>
                      </li>
                      <li>
                        <a href="#/">Restaurant POS</a>
                      </li>
                      <li>
                        <a href="#/">Bar POS</a>
                      </li>
                      <li>
                        <a href="#/">Inventory Management</a>
                      </li>
                      <li>
                        <a href="#/">Stores/Warehouse Management</a>
                      </li>
                      <li>
                        <a href="#/">Pool</a>
                      </li>
                      <li>
                        <a href="#/">Gym</a>
                      </li>
                      <li>
                        <a href="#/">Interactive Reporting</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Sidebar end */}
              </div>
              {/* Sidebar Col end */}
              <div className="col-xl-8 col-lg-8">
                <div className="content-inner-page">
                  <h2 className="column-title mrt-0">PMS Module</h2>
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        The app is a web base software very easy to operate by
                        writing information via card management system to
                        achieve the integration registration of doors locking
                        and unlocking, conserving power, shopping, parking,
                        fitness or the Gym centers, Stock keeping room,
                        Restaurant, conference rooms, Elevator and other
                        services areas into one card. It is the best solution
                        for hospitality management. Our lock SDK is also
                        friendly and feasible to accept integration with other
                        hotel management software like Easy, Opera, Fidelio,
                        Oracle and others
                      </p>
                      <p>
                        The software is well equipped to monitor all other
                        branches from the headquarters generating reports,
                        bills, receipts from the restaurant, bar ,enables you to
                        print consolidated financial statement analysis and
                        sections for your inventories for clear
                        accountabilities.
                      </p>
                    </div>
                    {/* col end */}
                  </div>
                  {/* 1st row end*/}
                  <div className="gap-40" />
                  <div id="page-slider" className="page-slider">
                    <div className="item">
                      <img
                        loading="lazy"
                        className="img-fluid"
                        src="images/pms/login.JPG"
                        alt="project-slider"
                      />
                    </div>
                    <div className="item">
                      <img
                        loading="lazy"
                        className="img-fluid"
                        src="images/pms/login.JPG"
                        alt="project-slider"
                      />
                    </div>
                  </div>
                  {/* Page slider end */}
                  <div className="gap-40" />
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="column-title-small">
                        What Makes Us Different
                      </h3>
                      <p>we got the solution to all your problems</p>
                      <ul className="list-arrow">
                        <li>
                          We offer Rapid response when issues are reported
                        </li>
                        <li>Regular training of customers on the system</li>
                        <li>Self explained User Interface</li>
                      </ul>
                    </div>
                  </div>
                  {/*2nd row end */}
                  <div className="gap-40" />
                </div>
                {/* Content inner end */}
              </div>
              {/* Content Col end */}
            </div>
            {/* Main row end */}
          </div>
          {/* Conatiner end */}
        </section>
        {/* Main container end */}

        <div className="container py-3">
          <div className="row">
            <div className="col-md-12">
              <h3 className="column-title-small text-center">
                PMS user interface{" "}
              </h3>
              <img
                src="images/pms/2.jpeg"
                className="img-fluid"
                alt="project-slider"
              />
              <div className="gap-40" />
              <div className="d-flex justify-content-between">
                <a
                  href="https://update.sanfiley.com/publish.htm"
                  style={{ color: "blue", textDecoration: "underline" }}>
                  Click here to download our trial version of the PMS software
                </a>
                <a
                  href="https://app.sanfiley.com/"
                  style={{ color: "blue", textDecoration: "underline" }}>
                  Click here to view our mobile version of the PMS App
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Pms;
