export const myData = [
  {
    id: 1,
    imageUrl: require("../../Assets/projects/AKORLIS HOTEL-SANFILEY.jpg"),
  },
  {
    id: 2,
    imageUrl: require("../../Assets/projects/back.jpg"),
  },
  {
    id: 3,
    imageUrl: require("../../Assets/projects/BASE BOUTIQUE -SANFILEY.jpg"),
  },
  {
    id: 4,
    imageUrl: require("../../Assets/projects/BASELODGE-SANFILEY.jpg"),
  },
  {
    id: 5,
    imageUrl: require("../../Assets/projects/CHILL RESOR G.jpg"),
  },
  {
    id: 6,
    imageUrl: require("../../Assets/projects/cystal rose.jpg"),
  },
  {
    id: 7,
    imageUrl: require("../../Assets/projects/DELLAGIO HOTEL.jpg"),
  },
  {
    id: 8,
    imageUrl: require("../../Assets/projects/ENAMEL-SANFILEY.jpg"),
  },
  {
    id: 9,
    imageUrl: require("../../Assets/projects/EXTEE CRYSTALL HOTEL.jpg"),
  },
  // {
  //   id: 10,
  //   imageUrl: require("../../Assets/projects/GLOBAL SMART -SANFILEY.jpg"),
  // },
  {
    id: 11,
    imageUrl: require("../../Assets/projects/HIGHBRIDGE HOTEL.jpg"),
  },
  {
    id: 12,
    imageUrl: require("../../Assets/projects/IKES VILLAGE.jpg"),
  },
  {
    id: 13,
    imageUrl: require("../../Assets/projects/JOCOM.jpg"),
  },
  {
    id: 14,
    imageUrl: require("../../Assets/projects/LIBERIA - PASSION HOTEL.jpg"),
  },
  {
    id: 15,
    imageUrl: require("../../Assets/projects/LINDADOR.jpg"),
  },
  {
    id: 16,
    imageUrl: require("../../Assets/projects/MARIAM HOTEL-SANFILEY.jpg"),
  },
  {
    id: 17,
    imageUrl: require("../../Assets/projects/millenium-   SANFILEY.jpg"),
  },
  {
    id: 18,
    imageUrl: require("../../Assets/projects/MONARCH.jpg"),
  },
  {
    id: 19,
    imageUrl: require("../../Assets/projects/PEMPAMSIE HOTEL.jpg"),
  },
  {
    id: 20,
    imageUrl: require("../../Assets/projects/PROVIDENCE TERRACE -SANFILEY (2).jpg"),
  },
  {
    id: 21,
    imageUrl: require("../../Assets/projects/ROYAL IRENIC HOTEL.jpg"),
  },
  {
    id: 22,
    imageUrl: require("../../Assets/projects/sef.jpg"),
  },
  {
    id: 23,
    imageUrl: require("../../Assets/projects/sir bee.jpg"),
  },
  {
    id: 24,
    imageUrl: require("../../Assets/projects/TIMA SARA HOTEL.jpg"),
  },
  {
    id: 25,
    imageUrl: require("../../Assets/projects/UCC NEW SET -SANFILEY.jpg"),
  },
  {
    id: 26,
    imageUrl: require("../../Assets/projects/UCC SCHOOL OF GRADUATE STUDIES-SANFILEY MAY 2022.jpg"),
  },
  {
    id: 27,
    imageUrl: require("../../Assets/projects/VILANDRO.jpg"),
  },
  {
    id: 28,
    imageUrl: require("../../Assets/projects/villadora.jpg"),
  },

  {
    id: 29,
    imageUrl: require("../../Assets/projects/YEGOALA HOTEL.jpg"),
  },
];
