import React from "react";

const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer bg-overlay">
        <div className="footer-main">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-6 footer-widget footer-about">
                <h3 className="widget-title">About Us</h3>
                {/* <img loading="lazy" class="footer-logo" src="images/footer-logo.png" alt="Constra"> */}
                <h2 className="d-block" id="san">
                  Sanfiley
                </h2>
                <p>Experts in Hotel locking systems</p>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="https://facebook.com/" aria-label="Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/" aria-label="Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/" aria-label="Instagram">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://eitegh.com/" aria-label="Github">
                        {" "}
                        eitegh.com
                      </a>
                    </li>
                    {/* <br />
                    <section className="py-3">
                      <h1 style={{ color: "white" }}>EITEGH TECHNOLOGIES</h1>
                    </section> */}
                    <section className="py-3">
                      <img
                        src="images/about/logo.PNG"
                        alt="linkedin"
                        width={80}
                        height={80}
                      />
                    </section>
                  </ul>
                </div>
                {/* Footer social end */}
              </div>
              {/* Col end */}
              <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
                <h3 className="widget-title">Working Hours</h3>
                <div className="working-hours">
                  We work 7 days a week, every day excluding major holidays.
                  Contact us if you have an emergency, with our Hotline and
                  Contact form.
                  <br />
                  <br /> Monday - Friday:{" "}
                  <span className="text-right">10:00 - 16:00 </span>
                  <br /> Saturday:{" "}
                  <span className="text-right">12:00 - 15:00</span>
                  <br /> Sunday and holidays:{" "}
                  <span className="text-right">09:00 - 12:00</span>
                </div>
              </div>
              {/* Col end */}
              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
                <h3 className="widget-title">Reach Us</h3>
                {/* <br /> */}
                <section className="py-1">
                  <a href="https://www.eitegh.com/">
                    <h3 style={{ color: "#ffb600" }}>EITEGH TECHNOLOGIES</h3>
                  </a>
                </section>
                <ul className="list-arrow">
                  <li>
                    <a href="tel:0303943197"> 030 3943197 </a>
                  </li>
                  <li>
                    <a href="tel:+233599137254"> +233 (0) 599137254 </a>
                  </li>
                  <li>
                    <a href="mailto:info@sanfiley.com"> info@sanfiley.com </a>
                  </li>
                  <li>
                    <a href="mailto:sanfiley@gmail.com"> sanfiley@gmail.com </a>
                  </li>
                  <li>
                    <a href="mailto:eitontorsh@outlook.com">
                      {" "}
                      eitontorsh@outlook.com{" "}
                    </a>
                  </li>
                </ul>
              </div>
              {/* Col end */}
            </div>
            {/* Row end */}
          </div>
          {/* Container end */}
        </div>
        {/* Footer main end */}
        <div
          id="back-to-top"
          data-spy="affix"
          data-offset-top={10}
          className="back-to-top position-fixed">
          <button className="btn btn-primary" title="Back to Top">
            <i className="fa fa-angle-double-up" />
          </button>
        </div>
      </footer>
      {/* Footer end */}
      <div id="top-bar" className="top-bar p-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <ul className="top-info text-center text-md-left">
                <li>
                  <i className="fas fa-map-marker-alt" />{" "}
                  <p className="info-text">Mallam Chaba Junction, Accra</p>
                </li>
              </ul>
            </div>
            {/*/ Top info end */}
            <div className="col-lg-4 col-md-4 top-social text-center text-md-right">
              <ul className="list-unstyled">
                <li>
                  <a title="Facebook" href="https://facebbok.com/">
                    <span className="social-icon">
                      <i className="fab fa-facebook-f" />
                    </span>
                  </a>
                  <a title="Twitter" href="https://twitter.com/">
                    <span className="social-icon">
                      <i className="fab fa-twitter" />
                    </span>
                  </a>
                  <a title="Instagram" href="https://instagram.com/">
                    <span className="social-icon">
                      <i className="fab fa-instagram" />
                    </span>
                  </a>
                  <a title="website" href="https://eitegh.com/">
                    <span className="social-icon">eitegh.com</span>
                  </a>
                </li>
              </ul>
            </div>
            {/*/ Top social end */}
          </div>
          {/*/ Content row end */}
        </div>
        {/*/ Container end */}
      </div>
      {/*/ Topbar end */}
    </>
  );
};

export default Footer;
