import React from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const Contact = () => {
  return (
    <div>
      <Header />
      <div>
        <div
          id="banner-area"
          className="banner-area"
          style={{ backgroundImage: "url(./images/new/header-1.jpg)" }}>
          <div className="banner-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-heading">
                    <h1 className="banner-title">About</h1>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#/">Company</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          About Us
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Banner area end */}

        <section id="main-container" className="main-container">
          <div className="container">
            <div className=" row py-2">
              <div className="col-12">
                <p className="" style={{ fontWeight: "bolder" }}>
                  Sanfiley is brand name originated from the indigenous Ghanaian
                  local language which means keys. We are expert in customized
                  software developing, installation of RFID key cards locks,
                  keypad lock, fingerprint lock, QR code locks and the relative
                  products which are widely applying to the Hospitality,
                  Apartment, Universities ,Institutions and Factory solutions
                </p>
              </div>
            </div>

            {/*/ Title row end */}
            <div className="row">
              <div className="col-md-4">
                <div className="ts-service-box-bg text-center h-100">
                  <span className="ts-service-icon icon-round">
                    <i className="fas fa-map-marker-alt mr-0" />
                  </span>
                  <div className="ts-service-box-content">
                    <h4>Visit Our Office</h4>
                    <p>Mallam Chaba Junction, Accra</p>
                  </div>
                </div>
              </div>
              {/* Col 1 end */}
              <div className="col-md-4">
                <div className="ts-service-box-bg text-center h-100">
                  <span className="ts-service-icon icon-round">
                    <i className="fa fa-envelope mr-0" />
                  </span>
                  <div className="ts-service-box-content">
                    <h4>Email Us</h4>
                    <p>sanfiley@gmail.com</p>
                    <p>sales@sanfiley.com</p>
                    <p>info@sanfiley.com</p>
                    <p>eitontorsh@outlook.com</p>
                  </div>
                </div>
              </div>
              {/* Col 2 end */}
              <div className="col-md-4">
                <div className="ts-service-box-bg text-center h-100">
                  <span className="ts-service-icon icon-round">
                    <i className="fa fa-phone-square mr-0" />
                  </span>
                  <div className="ts-service-box-content">
                    <h4>Call Us</h4>
                    <p>+233 (0) 244535932</p>
                    <p>+233 (0) 599137254</p>
                    <p>+233 (0) 303943197</p>
                  </div>
                </div>
              </div>
              {/* Col 3 end */}
            </div>
            {/* 1st row end */}

            <div className="gap-60" />
            <div className="google-map">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    style={{ width: "100%", height: "100%" }}
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=sanfiley&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    title="Sanfiley"
                  />
                  <a href="https://123movies-to.org" />
                  <br />
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".mapouter{position:relative;text-align:right;height:500px;width: 100%;}",
                    }}
                  />
                  <a href="https://www.embedgooglemap.net">
                    embedgooglemap.net
                  </a>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="gap-40" />
          </div>
          {/* Conatiner end */}
        </section>
        {/* Main container end */}
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
