import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Projects from "./Components/Projects/Projects";
import Pms from "./Components/Services/Pms";
import Locks from "./Components/Locks/Locks";
import About from "./Components/Company/About";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/pms" element={<Pms />} />
        <Route path="/locks" element={<Locks />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;
