import React from "react";

const Header = () => {
  return (
    <div>
      {/* Header start */}
      <header id="header" className="header-two">
        <div className="site-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <div className="logo">
                    <a className="d-block" href="/">
                      <h2 className="d-block" id="san">
                        Sanfiley
                      </h2>
                    </a>
                  </div>
                  {/* logo end */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    id="navbar-collapse"
                    className="collapse navbar-collapse">
                    <ul className="nav navbar-nav ml-auto align-items-center">
                      <li className="nav-item active">
                        <a className="nav-link" href="/">
                          Home{" "}
                        </a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" href="/about">
                          About
                        </a>
                      </li>
                      <li className="nav-item"></li>

                      <li className="nav-item">
                        <a className="nav-link" href="/pms">
                          PMS
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/projects">
                          Projects
                        </a>
                      </li>
                      <li className="nav-item"></li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/*/ Col end */}
            </div>
            {/*/ Row end */}
          </div>
          {/*/ Container end */}
        </div>
        {/*/ Navigation end */}
      </header>
      {/*/ Header end */}
    </div>
  );
};

export default Header;
