import React from "react";
import Header from "../Views/Header";
import Footer from "../Views/Footer";
import { myData } from "../data/cards.js";
// import Data from "../data/cards";
// import Data from "../data/projects.json";

const Projects = () => {
  console.log(myData);
  return (
    <div className="body-inner">
      <Header />
      <div>
        <div
          id="banner-area"
          className="banner-area"
          style={{ backgroundImage: "url(./images/new/header-1.jpg)" }}>
          <div className="banner-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-heading">
                    <h1 className="banner-title">Projects</h1>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#/">Projects</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          All Projects
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                {/* Col end */}
              </div>
              {/* Row end */}
            </div>
            {/* Container end */}
          </div>
          {/* Banner text end */}
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          {myData.map((item) => {
            return (
              <div className="col-lg-2 col-md-3 p-3" key={item.id}>
                <div className="card">
                  <img
                    src={item.imageUrl}
                    className="card-img-top"
                    alt="companies"
                  />
                  {/* <div className="card-body">
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Projects;
